angular
  .module('account-check.directive', [])
  /* Utilisation de https://regexlib.com/ et https://rgxdb.com/ */
  /* @ngInject */
  .directive('accountPhone', function ($timeout, $parse) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, element, attrs, modelCtrl) {
        const std = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/; // N'importe quel numéro de téléphone
        let regexPhone = std;
        const spe = {
          1089: /^(?:0)\s*[1-9](?:[\s.-]*\d{2}){4}$/, // France métropolitaine (Rascol ne souhaite pas autoriser les dialing codes)
          1199: /^(?:(?:\+|00|0)((262|692)|(263|693)|508|(5|6)90|(5|6)94|(5|6|7)96|681|687|689))(?:[\s.-]*\d{2}){3,4}$/, // La Réunion,
          1159: /^(?:(?:\+|00|0)((262|692)|(263|693)|508|(5|6)90|(5|6)94|(5|6|7)96|681|687|689))(?:[\s.-]*\d{2}){3,4}$/, // Mayotte
          1211: /^(?:(?:\+|00|0)((262|692)|(263|693)|508|(5|6)90|(5|6)94|(5|6|7)96|681|687|689))(?:[\s.-]*\d{2}){3,4}$/, // Saint - Pierre - et - Miquelon
          1100: /^(?:(?:\+|00|0)((262|692)|(263|693)|508|(5|6)90|(5|6)94|(5|6|7)96|681|687|689))(?:[\s.-]*\d{2}){3,4}$/, // Guadeloupe, Saint-Martin et Saint-Barthélemy
          1108: /^(?:(?:\+|00|0)((262|692)|(263|693)|508|(5|6)90|(5|6)94|(5|6|7)96|681|687|689))(?:[\s.-]*\d{2}){3,4}$/, // French Guiana(Guyane)
          1156: /^(?:(?:\+|00|0)((262|692)|(263|693)|508|(5|6)90|(5|6)94|(5|6|7)96|681|687|689))(?:[\s.-]*\d{2}){3,4}$/, // Martinique
          1256: /^(?:(?:\+|00|0)((262|692)|(263|693)|508|(5|6)90|(5|6)94|(5|6|7)96|681|687|689))(?:[\s.-]*\d{2}){3,4}$/, // Wallis-et-Futuna
          1178: /^(?:(?:\+|00|0)((262|692)|(263|693)|508|(5|6)90|(5|6)94|(5|6|7)96|681|687|689))(?:[\s.-]*\d{2}){3,4}$/, // Nouvelle - Calédonie
          1195: /^(?:(?:\+|00|0)((262|692)|(263|693)|508|(5|6)90|(5|6)94|(5|6|7)96|681|687|689))(?:[\s.-]*\d{2}){3,4}$/ // French Polynesia
        };
        const stdPhoneMessage = 'Le numéro de téléphone renseigné<br /> ne correspond pas à un numéro <br /> de téléphone fixe'; // France métropolitaine
        const spePhoneMessages = {
          27: 'Le numéro de téléphone renseigné <br/> ne correspond pas à un numéro <br /> de téléphone fixe belge', // 27 => Belgique
          8: 'Die eingegebene Telefonnummer <br/> entspricht keiner Festnetznummer', // 8 => Allemagne
          114: 'Il numero di telefono inserito <br/> non corrisponde a un numero <br /> di rete fissa', // 114 => Italie
          72: 'El número de teléfono introducido <br/> no corresponde a un número <br /> de teléfono fijo', // 72 => Espagne
          215: 'Le numéro de téléphone renseigné <br/> ne correspond pas à un numéro <br /> de téléphone fixe', // 215 => Suisse
          189: 'The telephone number entered <br/> does not correspond to <br /> a landline number' // 189 => Royaume-Uni
        };

        const watcher = scope.$watch(attrs.accountPhone, function (idCountry) {
          regexPhone = (idCountry in spe) ? spe[idCountry] : std;
          refreshField(idCountry);
          if (attrs.ngModel) {
            const val = modelCtrl.$viewValue;
            $parse(attrs.ngModel).assign(scope, val);
          }
        });

        scope.$on('$destroy', function () {
          watcher();
        });

        modelCtrl.$validators.phone = phoneValidator;

        function phoneValidator(modelValue) {
          if (!modelValue) {
            return true;
          }

          return regexPhone.test(modelValue);
        }

        function refreshField(country) {
          modelCtrl.$setValidity('phone', true);
          $timeout(function () {
            scope.$apply(function () {
              const message = (country in spePhoneMessages) ? spePhoneMessages[country] : stdPhoneMessage;
              attrs.$set('phone-notification', message);
              modelCtrl.$validate();
            });
          });
        }
      }
    };
  })
  //Regex mobile par pays
  // /^(\+33|0033|\(\+33\)|0)(6|7)( ?\d){8}$/ // France
  // /^(?:\+324|04)( ?\d){8}$/, // 27 => Belgique
  // /^((01[0-9])\d{9}$|(1[0-9])\d{9}$|((00\d{5,6}|\+\d{4,6}|\+[(]\d{1,3}[)]\d{3}))(|[-\/])\d{8}$)/, // 8 => Allemagne
  // /^([+]39)?((38[{8,9}|0])|(34[{7-9}|0])|(36[6|8|0])|(33[{3-9}|0])|(32[{8,9}]))([\d]{7})$/, // 114 => Italie
  // /^34\s?(?:6[0-9]|7[1-9])[0-9]\s?[0-9]{3}\s?[0-9]{3}$/, // 72 => Espagne
  // /^(((\+41)(\s?)(\-?))|(0))(7)(\d{1})(\s?)(\-?)(\d{3})(\s?)(\-?)(\d{2})(\s?)(\-?)(\d{2})/, // 215 => Suisse
  // /^(\+44\s?7\d{3}|\(?07\d{3}\)?)\s?\d{3}\s?\d{3}$/ // 189 => Royaume-Uni
  /* @ngInject */
  .directive('accountMobile', function ($timeout, $parse) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, element, attrs, modelCtrl) {
        const std = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/; // N'importe quel numéro de téléphone
        let regexMobile = std;
        const regexByCountryId = {
          //Je double les échappements (anti-slash) car c'est désormais une chaine de caractère qu'on fourni à RegExp
          1089: '(((\\+|00)33\\s?|0)[67](\\s?\\d{2}){4})', // France métropolitaine voici l'équivalent non échappé: ((\+|00)33\s?|0)[67](\s?\d{2}){4})
          1035: '^(\\+?32|0)4[56789]\\d{7}$' //Belgique voici l'équivalent non échappé: /^(\+?32|0)4[56789]\d{7}$/
        };
        const stdMobileMessage = 'Le numéro de téléphone renseigné <br /> ne correspond pas à un numéro <br /> de téléphone portable'; // France métropolitaine
        const speMobileMessage = {
          27: 'Le numéro de téléphone renseigné <br/> ne correspond pas à un numéro <br /> de téléphone portable', // 27 => Belgique
          8: 'Die eingegebene Telefonnummer <br/> entspricht keiner Mobiltelefonnummer', // 8 => Allemagne
          114: 'Il numero di telefono inserito <br/> non corrisponde a un numero di cellulare', // 114 => Italie
          72: 'El número de teléfono introducido <br/> no corresponde a un número <br /> de teléfono móvil', // 72 => Espagne
          215: 'Le numéro de téléphone renseigné<br/> ne correspond pas à un numéro<br/> de téléphone portable', // 215 => Suisse
          189: 'The telephone number entered <br/> does not correspond to a <br /> mobile telephone number' // 189 => Royaume-Uni
        };

        const countryCodeAndIdMapping =
        {
          'FR': 1089,
          'BE': 1035
        };

        const watcher = scope.$watchGroup([attrs.accountMobile, attrs.shippingMobile], mobileChanged);

        scope.$on('$destroy', function () {
          watcher();
        });

        modelCtrl.$validators.mobile = mobileValidator;

        function mobileChanged(newValues) {

          const visitorCountryId = newValues[0];
          const shippingCountryCode = newValues[1];

          /* If
          Add new shipping address
          Or create account
          */
          if (!shippingCountryCode) {
            regexMobile = (visitorCountryId in regexByCountryId) ? new RegExp(regexByCountryId[visitorCountryId]) : std;
          } else if (visitorCountryId && shippingCountryCode) { /* Else If Fill a phone number from shipping list */
            const shippingCountryId = (shippingCountryCode in countryCodeAndIdMapping) ? countryCodeAndIdMapping[shippingCountryCode] : null;
            regexMobile = '^';
            if (shippingCountryId) {
              regexMobile += regexByCountryId[visitorCountryId];
              if (visitorCountryId !== shippingCountryId) {
                regexMobile += '|'.concat(regexByCountryId[shippingCountryId]);
              }
            }
            regexMobile += '$';
            regexMobile = new RegExp(regexMobile);
          } else {
            regexMobile = std;
          }

          refreshField(visitorCountryId);

          if (attrs.ngModel) {
            const val = modelCtrl.$viewValue;
            $parse(attrs.ngModel).assign(scope, val);
          }
        }

        function mobileValidator(modelValue) {
          if (!modelValue) {
            return true;
          }

          return regexMobile.test(modelValue);
        }

        function refreshField(country) {
          modelCtrl.$setValidity('mobile', true);
          $timeout(function () {
            scope.$apply(function () {
              const message = (country in speMobileMessage) ? speMobileMessage[country] : stdMobileMessage;
              attrs.$set('mobile-notification', message);
              modelCtrl.$validate();
            });
          });
        }
      }
    };
  });
