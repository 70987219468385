import { cloneDeep } from 'lodash-es';
import 'Modules/Finder/import/service.parse.js';

export default angular
  .module('finder.service', ['finder.service.parse'])

  /* @ngInject */
  .factory('FinderService', function ($http, $q, $cacheFactory, FinderParseService, GA4Service) {
    let deferred = $q.defer();
    let ctrl;
    const cache = $cacheFactory('finderFiltersCache', { capacity: 30 });

    const QueryType = {
      BoolQuery: 0,
      BoostingQuery: 1,
      TermQuery: 2,
      MatchQuery: 3,
      MultiMatchQuery: 4,
      RangeQuery: 5,
      NestedQuery: 6,
      MatchPhraseQuery: 7,
      MatchPhrasePrefixQuery: 8
    };

    const service = {
      setCtrl,
      getCtrl,
      get,
      cancel
    };
    return service;

    ////////////
    function setCtrl(value) {
      ctrl = value;
    }

    function getCtrl() {
      return ctrl;
    }

    async function get(params) {
      deferred.resolve('cancel');
      deferred = $q.defer();

      const data = {
        TextQuery: params.query,
        From: params.from * params.size,
        Size: params.size,
        FiltersSelected: [],
        FacetsSelected: params.facets,
        QueryType: QueryType.BoostingQuery,
        NegativeTermsQuery:
          [
            {
              FieldName: 'KeyWebAspect',
              FieldValue: 'Advice'
            },
            {
              FieldName: 'KeyWebAspect',
              FieldValue: 'Content'
            }
          ],
        NegativeBoost: 0.5
      };

      const hash = angular.toJson(data);
      let results = null;
      results = cache.get(`filters_${hash}`);
      if (!results) {
        try {
          const { data: response } = await $http({
            url: '/elssearch/query',
            method: 'POST',
            headers: {
              'Content-type': 'application/json',
              'X-Requested-With': 'jsonHttpRequest'
            },
            data,
            timeout: deferred.promise
          });
          const items = await FinderParseService.parse(params.query, response.products);
          results = {
            count: response.count,
            facets: response.facets,
            items,
            affiliation: response?.affiliation
          };
          cache.put(`filters_${hash}`, cloneDeep(results));
        } catch (error) {
          console.error(error);
          deferred.reject(error);
        }
      }

      try {
        GA4Service.search(params.query, results?.items, results?.affiliation);
      } catch (error) {
        console.error(error);
      }

      deferred.resolve(results);
      return deferred.promise;
    }

    function cancel() {
      deferred.resolve('cancel');
    }
  })

  /* @ngInject */
  .filter('matchstring', function ($sce) {
    return (value, str) => {
      if (!value || !str) {
        return;
      }
      if (!value.match || !value.replace) {
        value = value.toString();
      }
      let matches, reg;

      const words = str.split(' ');
      let tmp;
      words.forEach(word => {
        tmp = word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        if (tmp.length > 2) {
          reg = new RegExp(tmp, 'ig');
          matches = value.match(reg);
          if (matches) {
            value = value.split(reg).join(`#|#${matches[0]}#/#`);
          }
        }
      });

      value = value.split('#|#').join('<span class="match">').split('#/#').join('</span>');
      return $sce.trustAsHtml(value).valueOf();
    };
  });
