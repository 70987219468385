export default angular
  .module('finder.service.parse', [])

  /* @ngInject */
  .factory('FinderParseService', function ($filter) {
    const service = {
      parse
    };
    return service;

    ////////////
    function parse(search, items) {
      return items.map(item => {
        const obj = {};
        obj.designationHtml = $filter('matchstring')(item.Designation, search);
        obj.htmlReduction = !item.Price.HasDiscount ? '' : `<span class="reduction">-${$filter('discount')(item.Price.Discount)}</span>`;
        obj.htmlPrice = `<span class="price new">${$filter('price')(item.Price, 'final-price')}</span>`;
        if (item.Price.HasDiscount) {
          obj.htmlPrice = `<span class="price old ml-5">${$filter('price')(item.Price)}</span>${obj.htmlPrice}`;
        }
        obj.HTMLShortDescription = item.HTMLShortDescription;
        obj.isComparable = item.Themes.some(th => th.KeyTheme === 'Comparateur');
        obj.AllowAddToCart = item.AllowAddToCart;
        obj.ReviewsInfo = item.ReviewsInfo ? { Count: item.ReviewsInfo.Count, Average: item.ReviewsInfo.Average.toFixed(1) } : null;
        obj.isFlash = item.Themes.some(th => th.KeyTheme === 'Ventes_Flash');
        obj.flags = item.Themes?.filter(th => th.Text1 === 'Madein')?.map(th => ({ id: th.IDTheme, img: `/img/flags/${th.KeyTheme.toLowerCase()}.svg` }));
        obj.gift = item.Properties?.filter(pp => pp.KeyProperty === 'Cadeau')?.map(pp => ({ Html: `<span>${pp.Designation}</span><span class="bold">${pp.Value}</span>` }));
        obj.colors = item.Properties?.filter(pp => pp.KeyProperty === 'Nb coloris')?.map(pp => `<img src="/img/colors.svg" width="12" /><span>${pp.Value}</span>`);
        obj.properties = item.Properties?.filter(
          pp =>
            pp.KeyProperty === 'Echantillon' ||
            pp.KeyProperty === 'Nb pelotes' ||
            pp.KeyProperty === 'Rendement' ||
            pp.KeyProperty === 'Composition' ||
            pp.KeyProperty === 'Outillage'
        )?.map(pp => ({ value: pp.Value }));
        obj.isNew = new Date() - new Date(item.CreationDate) <= 7776000000 ? true : false; // 7776000000ms = 90jrs
        obj.KeyWebAspect = item.KeyWebAspect;
        return {
          IDProduct: item.IDProduct,
          Designation: item.Designation,
          URL: item.URL,
          IDPicture: item.MainPicture ? item.MainPicture.IDPicture : item.IDPicture,
          Price: item.Price,
          IsGroupingProduct: item.IsGroupingProduct,
          IsCustomizable: !item.IsCustomizable,
          Themes: item.Themes.filter(theme => theme.KeyTheme === 'Nouveaute' || theme.KeyTheme === 'Promotion'),
          Availability: item.Availability,
          min: item.MinSaleQuantity,
          step: item.ModuloSaleQuantity,
          ...obj
        };
      });
    }
  });
